<template>
    <div>
      <div style="text-align: center">
        <h3>Tambah Pengguna</h3>
      </div>
      <br />
      <form v-on:submit.prevent="addContact">
        <label>Nama Pengguna*</label>
        <input type="text" class="form-control" v-model="form.name" autofocus required />
        <br />
        <label>Nomor Handphone*</label>
        <input type="number" class="form-control" v-model="form.number" required />
        <br />
        <label>Email*</label>
        <input type="email" class="form-control" v-model="form.email" required />
        <br />
        <label>Password{{ !form.id ? '*' : '' }}</label>
        <input type="password" class="form-control" v-model="form.password" :required="!form.id" />
        <br />

        <label>Max Device*</label>
        <input type="number" class="form-control" v-model="form.max_device" required />
        <br />

        <label>Max CS*</label>
        <input type="number" class="form-control" v-model="form.max_cs" required />
        <br />

        <label>Expired At*</label>
        <input type="date" class="form-control" v-model="form.expired_at" required />
        <br />

        <!-- <label for="">Hak Akses</label>
        <select v-model="form.role" class="form-control" required>
          <option v-for="d in roles" :key="d.value" :value="d.value" >{{ d.text }}</option>
        </select> -->

        <label for="">Status</label>
        <select v-model="form.status" class="form-control" required>
          <option value="0" >Tidak AKtif</option>
          <option value="1" >AKtif</option>
        </select>

        <br>

        <div class="button-group d-flex pt-25">
            <button type="submit" class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
              simpan
            </button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      defaultValue: {
        type: Object,
        required: false,
      },
    },
    data() {
      return {
        form: {
          name: "",
          number: "",
          email: "",
          password: "",
          max_device: 0,
          max_cs: 0,
          expired_at: "",
          role: "user",
          status: 0,
        },
        roles: [
          {
            value: "user",
            text: "User",
          },
          {
            value: "admin",
            text: "Admin",
          },
          {
            value: "agency",
            text: "Agensi",
          },
        ]
      };
    },
    watch: {
      defaultValue: {
        handler: function (val) {
          // console.log('val', val)
          this.form = val;
        },
        deep: true,
      },
    },
    mounted() {
      // console.log('defaultValue', this.defaultValue)
      if (this.defaultValue && this.defaultValue.id) {
        this.form = this.defaultValue;
      }
    },
    methods: {
      async addContact(){
        try{
          if (this.form.id) {
            // EDIT MODE
            let response = await this.$store.dispatch('agency_user_update', {
              ...this.form,
              user: {
                id: this.form.id,
              },
              max_device: parseInt(this.form.max_device),
              max_cs: parseInt(this.form.max_cs),
              role: 'user',
              status: parseInt(this.form.status),
            })
            this.$toast.success(response.data.message);
            this.$root.$emit("toggle_modal", "modal_user")
            this.$root.$emit("refresh_table", true)
            return;
          }

          // CREATE MODE
          let response = await this.$store.dispatch('agency_user_create', {
            ...this.form,
            max_device: parseInt(this.form.max_device),
            max_cs: parseInt(this.form.max_cs),
            role: 'user',
            status: parseInt(this.form.status),
          })
          this.$toast.success(response.data.message);
          this.$root.$emit("toggle_modal", "modal_user")
          this.$root.$emit("refresh_table", true)
        }catch(err){
          console.log('error', err)
          this.$toast.error(err.response.data.message);
        }
      },
    },
  };
  </script>
  